<template>
  <div>
    <b-modal id="my-modal" hide-footer ref="myModal" title="Chỉnh sửa quyền">
      <b-form class="col-12">
        <b-form-group label="Uri">
          <b-form-input v-model="name" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group label="Nhóm quyền">
          <b-form-input
            v-model="group"
            placeholder="Tên nhóm quyền"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Mô tả">
          <b-form-input
            v-model="description"
            placeholder="Nhập mô tả ngắn"
          ></b-form-input>
        </b-form-group>
        <b-row style="float: right">
          <b-button type="submit" variant="primary" v-on:click="updateItem()"
            >Cập nhật
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Quản lý quyền</h6>
        </template>
        <b-form class="col-12">
          <b-row>
            <b-col>
              <b-form-group label="Tìm kiếm">
                <b-form-input
                  v-model="input.name"
                  placeholder="api hoặc tên nhóm"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="" style="margin-top: 30px">
                <b-button type="submit" variant="primary" v-on:click="listAll()"
                  >Tìm kiếm
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <p style="color: red">
          Phải có mô tả ngắn thì quyền mới được hiển thị ở màn thêm mới vai trò
        </p>
        <hr />
        <b-table
          table-class="datatable-table"
          hover
          tbody-tr-class="datatable-row"
          thead-tr-class="datatable-row"
          thead-class="datatable-head"
          details-td-class="datatable-cell"
          :items="items"
          :fields="fields"
        >
          <template #cell(options)="data">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="showUpdate(data.item)"
              size="sm"
              >Chỉnh sửa
            </b-button>
            &nbsp;
            <b-button
              class="mr-2"
              variant="outline-danger"
              @click="deleteItem(data.item.id)"
              size="sm"
              >Xoá
            </b-button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="view.currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="view.paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  components: {},
  mixins: [Common],
  data() {
    return {
      id: null,
      name: null,
      group: null,
      description: null,
      items: [],
      input: {
        name: null,
      },
      fields: [
        { id: "Id" },
        { method: "Method" },
        { name: "Uri" },
        { group: "Nhóm" },
        { description: "Mô tả ngắn" },
        { options: "Tùy chọn" },
      ],
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý quyền", route: "permission" },
    ]);
  },
  methods: {
    listAll() {
      let params = {
        page: this.view.currentPage,
      };
      if (this.input.name != null || this.input.name !== "") {
        params.search = this.input.name;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.listAllPermission(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.items = response.data.data.data;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage = response.data.data.last_page;
            this.view.currentPage = response.data.data.current_page;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
        });
    },
    deleteItem(id) {
      if (!confirm("Bạn có chắc muốn xóa?")) {
        return;
      }
      CmsRepository.deletePermission(id)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", "Lỗi rồi");
          } else {
            this.listAll();
          }
        })
        .catch(() => {});
    },
    showUpdate(data) {
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.group = data.group;
      this.$refs["myModal"].show();
    },
    updateItem() {
      let params = {
        description: this.description,
        group: this.group,
      };
      CmsRepository.updatePermission(this.id, params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", "Lỗi rồi");
          } else {
            // this.listAll();
            //   this.items
            this.items.map((item) => {
              if (item.id === this.id) {
                item.description = this.description;
                item.group = this.group;
              }
              return item;
            });
          }
        })
        .catch(() => {});
      this.$refs["myModal"].hide();
    },
  },
  created() {
    this.listAll();
  },
  watch: {
    "view.currentPage"() {
      this.listAll();
    },
  },
};
</script>
<style lang="scss">
legend {
  font-weight: bold;
}
.bluex {
  color: #6262ff;
}
.red {
  color: red;
}
</style>
